
(function ($) {
    window.TableFilters = function () {
        var self = this;
        self.columnsDefs = [];

        self.vars = {
            filters_per_row: 4,
        };
        self.options = {
            domElement: '#datatable_ajax_wrapper .row:eq(0)',
            dates: {
                range_format: 'YYYY-MM-DD'
            },
            view: {
                slide_speed: 200,
                filter_form: {
                    open: false,
                    display_header: true,
                }
            },
            i18n: {
                filters_title: "Filters",
                boolean: {
                    yes: `Yes`,
                    no: `No`,
                    both: 'All',
                },
                date_range: {
                    "separator": " - ",
                    "applyLabel": "Apply",
                    "cancelLabel": "Cancel",
                    "fromLabel": "From",
                    "toLabel": "To",
                    format: 'YYYY-MM-DD',
                    "weekLabel": "W",
                    "daysOfWeek": [
                        "Su",
                        "Mo",
                        "Tu",
                        "We",
                        "Th",
                        "Fr",
                        "Sa"
                    ],
                    "monthNames": [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December"
                    ],
                    "firstDay": 1
                }
            },
        };
        self.buildFormContainer = function () {
            return {
                outer: $(`<div class="row">
                        ` + ( self.options.view.filter_form.display_header ?
                        `<div class="col-lg-12">
                            <h4 style="margin-left: 17px">` + self.options.i18n.filters_title + `<i class="caret"></i></h4>
                        </div>`
                        : ``) + ` 
                        <div id="table-filters-container" class="col-lg-12 `+(!self.options.view.filter_form.open ? 'hidden' : '')+`">
                        </div>
                        </div>`)
                    .css({
                        cursor: "pointer",
                        borderBottom: "1px solid #eee",
                        marginBottom: "20px",
                    })
                    .on('click', '.col-lg-12:first-of-type', function () {
                        $container = $(this).siblings('#table-filters-container');
                        if ($container.hasClass('hidden')) {
                            $container.slideDown(self.options.view.slide_speed, function () {
                                $container.removeClass('hidden');
                                $('#table-filters-container').css({
                                    paddingTop: '10px',
                                    borderTop: '1px dashed #eef1f5'
                                });
                            });
                        } else {
                            $container.slideUp(self.options.view.slide_speed, function () {
                                $container.addClass('hidden');
                                $('#table-filters-container').css({
                                    paddingTop: 'initial',
                                    borderTop: 'none',
                                });
                            });
                        }
                    }),
                appendFilter: function ($elem) {
                    this.outer.find('#table-filters-container').append($elem);
                }
            }
        };
        self.buildFilterContainer = function (sTitle) {
            return {
                outer: $(`<div class="col-lg-3">
                            <div class="form-group">
                            <label class="form-label">` + sTitle + `</label>
                            </div>
                            </div>`),
                setInput: function (el) {
                    this.outer.find('.form-group').append(el);
                }
            }
        };
        self.refreshTable = function (columnIndex, beforeCallBack = null, afterCallBack = null) {
            return function (e) {

                if (typeof beforeCallBack == "function") {
                    beforeCallBack.call(this, e);
                    if (e.isImmediatePropagationStopped()) {
                        return ;
                    }
                }
                self.tableObj.columns(columnIndex).search($(this).val()).draw();
                if (typeof afterCallBack == "function") {
                    afterCallBack.call(this, e);
                }
            };
        };

        self.buildFilterInput = function (cData, fConfig) {
            switch (fConfig.type) {
                case "boolean":
                    $fInput = $(`<div class="btn-group btn-group-sm">
                                    <button type="button" value="" class="btn btn-secondary">`+self.options.i18n.boolean.both+`</button>
                                    <button type="button" value="1" class="btn btn-secondary">`+self.options.i18n.boolean.yes+`</button>
                                    <button type="button" value="0" class="btn btn-secondary">`+self.options.i18n.boolean.no+`</button>
                                </div>`);
                    $fInput.find('[type*="button"]').on('click', self.refreshTable(cData.idx, function () {
                        $(this).siblings('button').removeClass('btn-primary');
                    }, function () {
                        $(this).addClass('btn-primary');
                    }));
                    break;
                case "input":
                    $fInput = $(`<div class="input-group-sm ">
                                <input type="text" class="form-control" placeholder="` + cData.sTitle + `"  id="filter_` + cData.data + `" />
                            </div>
                            `);
                    $fInput.find('[type*="text"]').on('change', self.refreshTable(cData.idx));
                    break;
                case "number":
                    $fInput = $(`<div class="input-group-sm ">
                                <input type="number" class="form-control" data-number-type="max" placeholder="` + cData.sTitle + `"  id="filter_` + cData.data + `" />
                            </div>
                            `);
                    $constraints = typeof fConfig.constraints == "object" ? fConfig.constraints :  null;
                    if (null !== $constraints) {
                        Object.keys($constraints).forEach(function (key, index) {
                            $fInput.find('[type*="number"]').attr(key, $constraints[key]);
                        });
                    }

                    $fInput.find('[type*="number"]').on('change', self.refreshTable(cData.idx, function (e) {
                        if ( typeof $(this).attr('min') !== "undefined") {
                            if (parseInt($(this).val(), 10) < parseInt($(this).attr('min'), 10) ) {
                                alert(cData.sTitle + ` cannot be less than `+ $(this).attr('min'));
                                e.stopImmediatePropagation()
                            }
                        }
                        if ( typeof $(this).attr('max') !== "undefined") {
                            if (parseInt($(this).val(), 10) > parseInt($(this).attr('max'), 10) ) {
                                alert(cData.sTitle + ` cannot be less than `+ $(this).attr('max'));
                                e.stopImmediatePropagation()
                            }
                        }
                    }));
                    break;
                case "number_range":
                    $fInput = $(`<div class="input-group-sm ">
                                <input type="number" class="form-control" data-number-type="max" placeholder="Max ` + cData.sTitle + `"  id="filter_` + cData.data + `_end" />
                            </div>
                            `);
                    $fInput.find('[type*="number"]').on('keyup', self.refreshTable(cData.idx));
                    break;
                case "date_range":
                    $val = '';
                    if ( typeof fConfig.startDate !== 'undefined' ) {
                        $val += fConfig.startDate + ' - ';
                    } else {
                        $val += moment().startOf('month').format('YYYY-MM-DD') + ' - ';
                    }
                    if ( typeof fConfig.endDate !== 'undefined' ) {
                        $val += fConfig.endDate;
                    } else {
                        $val +=  moment().endOf('month').format('YYYY-MM-DD');
                    }
                    $fInput = $(`<input type="text" class="form-control" placeholder="` + cData.sTitle + `" id="filter_` + cData.data + `">`).daterangepicker({
                        autoUpdateInput: false,
                        locale: self.options.i18n.date_range,
                        startDate: typeof fConfig.startDate !== 'undefined' ? fConfig.startDate : moment().startOf('month').format('YYYY-MM-DD'),
                        endDate: typeof fConfig.endDate !== 'undefined' ? fConfig.endDate : moment().endOf('month').format('YYYY-MM-DD'),
                    }).val($val);

                    $fInput.on('apply.daterangepicker', function (ev, picker) {
                        $(this).val(picker.startDate.format(self.options.dates.range_format) + ' - ' + picker.endDate.format(self.options.dates.range_format));
                        self.tableObj.ajax.dataType = "json";
                        $searchValue = {
                            "from": picker.startDate.format(self.options.dates.range_format),
                            "to": picker.endDate.format(self.options.dates.range_format),
                        };
                        self.tableObj.columns(cData.idx).search(JSON.stringify($searchValue)).draw();
                    });

                    break;
                case "datepicker":
                    $fInput = $(`<input type="text" class="form-control" placeholder="` + cData.sTitle + `" id="filter_` + cData.data + `">`).datepicker({});
                    $fInput.on('change', self.refreshTable(cData.idx));
                    break;
                case 'select':
                    $fInput = $(`<select class="form-control" id="filter_` + cData.data + `"><option>` + cData.sTitle + `</option></select>`).on('change', self.refreshTable(cData.idx));
                    break;
                case 'select2':
                    $fInput = $(`<select class="form-control filter_select2_` + cData.data + ` " id="to_rep_filter_` + cData.data + `"></select>`);
                    $ajaxData = typeof fConfig.data == 'undefined' ? {} : fConfig.data;
                    $ajaxData = $.extend({}, $ajaxData, {
                        id: cData.data,
                        text: cData.name,
                    });
                    $.ajax({
                        async: true,
                        cache: false,
                        url: fConfig.url,
                        data: $ajaxData,
                        method: fConfig.method ? fConfig.method : 'get',
                        dataType: fConfig.dataType,
                        error: function () {
                            $(document).ready(function () {
                                $("#to_rep_filter_" + cData.data).parents('[class*="col-lg"]:eq(0)').remove();
                            });
                        },
                        success: function (data) {
                            $fInput = $(`<select class="form-control filter_select2_` + cData.data + ` " id="filter_` + cData.data + `"><option></option></select>`);
                            data.forEach(function (fVal) {
                                $fInput.append(`<option value="` + fVal.id + `">` + fVal.text + `</option>`);
                            });
                            $select2InputConfig = {
                                theme: "bootstrap",
                                tags: false,
                                multiple: false,
                                width: 'inherit',
                                height: '34px',
                                allowClear: true,
                                placeholder: `Select ...`
                            };
                            $(document).ready(function () {
                                $(document).find("#to_rep_filter_" + cData.data).parents(":eq(0)").append($fInput);
                                $("#to_rep_filter_" + cData.data).remove();
                                $fInput.select2($select2InputConfig).on('change', self.refreshTable(cData.idx));
                            });
                        }
                    });
                    break;
                case 'none':
                default:
                    $fInput = null;
                    break;
            }
            if (null !== $fInput) {
                $fInput.find(':input').css({
                    height: '34px'
                });
            }

            return $fInput;
        };
        self.addFilterToForm = function ($columnDef) {
            $columnDef.filter = $.extend({
                type: null
            }, $columnDef.filter);

            $filterInput = self.buildFilterInput($columnDef, $columnDef.filter);

            if (null !== $filterInput) {
                $filterContainer = self.buildFilterContainer($columnDef.sTitle);
                $filterContainer.setInput($filterInput);
                self.form.appendFilter($filterContainer.outer);
            }
        };
        self.init = function (tableObj, options = {}) {
            self.tableObj = tableObj;
            self.options = $.extend({}, self.options, options);
            self.columnsDefs = tableObj.settings()[0].aoColumns;
            self.form = self.buildFormContainer();

            self.columnsDefs.forEach(self.addFilterToForm);

            $(self.options.domElement).prepend(self.form.outer);
        };

    };
}) (window.jQuery);